import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AppConfigServie {

    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    atualizarLayout(dados: any) {
		return this.http.post<any>(`${this.apiUrl}/gap/tema/layout`, dados)
			.toPromise()
			.then(res => res)
	}
}
