import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStoreService } from '../LocalStoreService/local-store.service';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private ls: LocalStoreService
  ) {}

  login(username: string, password: string): Observable<any> {
    return this.http.post(
      this.apiUrl + '/login/entrar',
      {
        username,
        password,
      }
    );
  }

  register(username: string, email: string, password: string): Observable<any> {
    return this.http.post(
      this.apiUrl + '/signup',
      {
        username,
        email,
        password,
      },
      httpOptions
    );
  }

  logout(): Observable<any> {
    return this.http.post(this.apiUrl + '/signout', {}, httpOptions).pipe(
      tap(() => {
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.sessionStorage.removeItem('auth-user');
      })
    );
  }

  refreshToken(): Observable<any> {
    const refreshToken = this.ls.getItem('refresh_token');
    return this.http.post(`${this.apiUrl}/login/refreshToken`, { refresh_token: refreshToken });
  }
}
