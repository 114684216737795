import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MenuChangeEvent } from './api/menuchangeevent';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class MenuService {
    private apiUrl = environment.apiUrl;
    private menuSource = new Subject<MenuChangeEvent>();
    private resetSource = new Subject();
    constructor(private http: HttpClient) { }

    menuSource$ = this.menuSource.asObservable();
    resetSource$ = this.resetSource.asObservable();

    onMenuStateChange(event: MenuChangeEvent) {
        this.menuSource.next(event);
    }

    reset() {
        this.resetSource.next(true);
    }

    getMenus(): Observable<any[]> {
        return this.http.get<any[]>(`${this.apiUrl}/menus/hotsys`);
    }
}
